import Image from "next/legacy/image"
import { useSession } from "@blitzjs/auth"
import { useRouter } from "next/router"
import React, { Suspense } from "react"
import { Heading, Text, Box, Button, HStack, Flex, Link, Container } from "@chakra-ui/react"
import LoadingSpinner from "app/components/LoadingSpinner"
import useBreakpoint from "app/components/UseBreakpoint"
import RatingComponent from "app/components/RatingComponent"
import { ProductReviewProp } from "app/utils/divorceHomePage"
import { useProductInterviews } from "app/utils/hooks/useProductInterviews"

const HeroContent = ({ product }: { product: ProductReviewProp }) => {
  const productsInterviews = useProductInterviews()
  const session = useSession()
  const router = useRouter()
  let isComplete = 0
  let productSlug = ""
  let updatedAt
  let link = "divorce"
  let state = "-"

  if (productsInterviews) {
    if (productsInterviews.divorce && productsInterviews.divorce[0]) {
      isComplete =
        JSON.parse(productsInterviews.divorce[0].completedStatus).completed ==
          JSON.parse(productsInterviews.divorce[0].completedStatus).total
          ? 2
          : 1

      productSlug = productsInterviews?.divorce[0]?.productSlug || ""
      updatedAt = productsInterviews?.divorce[0]?.updatedAt.toString() || "-"
      if (updatedAt && updatedAt.indexOf("-") > 0) {
        //to fix iOS issue
        updatedAt = updatedAt.replace(/-/g, "/")
      }
      updatedAt = new Date(updatedAt).toLocaleDateString()
      updatedAt = updatedAt === "Invalid Date" ? "-" : updatedAt

      const urlEndPoint = productsInterviews?.divorce[0]?.id || "divorce"
      link = productSlug + "/" + urlEndPoint
      state = productsInterviews?.divorce[0]?.state || "-"
    }
  }

  const statusIcon =
    isComplete == 2 ? "complete.svg" : isComplete == 1 ? "inprogress.svg" : "flag.svg"
  const status = isComplete == 2 ? "Complete" : isComplete == 1 ? "In progress" : "Not Started"
  const buttonLabel = isComplete == 2 ? "Review Questions" : isComplete == 1 ? "Continue" : "Start"
  const breakpoint = useBreakpoint()
  const classSubtitle = "subtitle " + breakpoint

  return (
    <Suspense fallback="Loading...">
      <Box
        background={{
          base: "linear-gradient(180deg, #F9F9F9 0%, #F2F2F2 100%)",
          md: "white",
        }}
        padding="20px 0"
      >
        <Container size="xl">
          <Flex
            bg={{ base: "none", lg: "gray.100" }}
            boxShadow={{ base: "none", md: "0px 2px 4px rgba(0, 0, 0, 0.32)" }}
            borderRadius="0px 6px 6px 0px"
            flexFlow={{ base: "column wrap", md: "row wrap", lg: "row nowrap" }}
          >
            <Box
              bg={{ base: "none", md: "gray.100" }}
              width={{ base: "100%", md: "50%", lg: "335px" }}
              height={{ base: "auto", lg: "36px" }}
              pl={{ base: "none", lg: "24px" }}
            >
              <Box>
                <Heading
                  size="3xl"
                  fontSize="28px"
                  fontWeight="800"
                  lineHeight="36px"
                  color="gray.900"
                  pt="24px"
                  textAlign={{ base: "center", lg: "left" }}
                >
                  {"Your Information"}
                </Heading>
              </Box>
              <Box>
                <Text
                  size="2xl"
                  fontSize="20px"
                  fontWeight="800"
                  lineHeight="27.28px"
                  width={{ base: "100%", lg: "261px" }}
                  height={{ base: "27px", lg: "27px" }}
                  mt={{ base: "24px", lg: "30px" }}
                  color={"gray.700"}
                  textAlign={{ base: "center", lg: "left" }}
                >
                  {"Premium Divorce Questions"}
                </Text>
              </Box>
              <Box height={{ base: "70px", md: "auto", lg: "auto" }}>
                <Button
                  onClick={() => router.push(`/documents/${link}`)}
                  variant="homehero"
                  textAlign={{ base: "center", lg: "left" }}
                  width={{ base: "100%", md: "260px", lg: "90%" }}
                  height={{ base: "64px", md: "40px", lg: "auto" }}
                  fontSize={{ base: "24px", md: "18px", lg: "20px" }}
                  m={{ base: "10px 0 0", md: "0 auto", lg: "0" }}
                >
                  {buttonLabel}
                </Button>
              </Box>
              <Box display={{ base: "block", lg: "none" }} m={"30px 0 10px"} textAlign={"center"}>
                <Text className={classSubtitle}>PDF Download</Text>
                <Link m={"30px 0"} pb={"30px"}>
                  <Box m={"10px auto 20px"}>
                    <Image
                      onClick={() => router.push(`/documents`)}
                      alt={"Hero Image"}
                      src={"/images/icons/pdf.svg"}
                      height={38}
                      width={42}
                    />
                  </Box>
                </Link>
                <Box borderTop={"1px solid #979797"} display={{ base: "block", md: "none" }}></Box>
              </Box>
            </Box>
            <Box
              width={{ base: "100%", md: "50%", lg: "794px" }}
              background={{ base: "none", md: "gray.50" }}
            >
              <Box
                height={{ base: "auto", lg: "204px" }}
                p={{ base: "10px", md: "30px 0", lg: "30px 0" }}
              >
                <HStack
                  spacing="24px"
                  flexFlow={{ base: "row wrap", lg: "row" }}
                  justifyContent={{ base: "center", lg: "center" }}
                  textAlign={"center"}
                  gap={{ base: "10px", md: "20px 5px", lg: "15px" }}
                >
                  <Box
                    width={{ base: "140px", lg: "130px" }}
                    h={{ base: "120px", lg: "80px" }}
                    flexFlow={"column wrap"}
                    pl={{ base: "20px", lg: "0" }}
                  >
                    <Box h="30px">
                      <Text className={classSubtitle}>Progress Status </Text>
                    </Box>
                    <Box h="40px">
                      <Box m={"0 auto"}>
                        <Image
                          alt={"Hero Image"}
                          src={`/images/icons/` + statusIcon}
                          width={40}
                          height={40}
                        />
                      </Box>
                      <Text>{status}</Text>
                    </Box>
                  </Box>
                  <Box
                    width={{ base: "120px", lg: "100px" }}
                    h={{ base: "120px", lg: "80px" }}
                    flexFlow={"column wrap"}
                  >
                    <Box h="40px">
                      <Text className={classSubtitle}>User ID</Text>
                    </Box>
                    <Box h="40px">
                      <Text>{session.userId ?? "NA"}</Text>
                    </Box>
                  </Box>
                  <Box
                    width={{ base: "120px", lg: "100px" }}
                    h={{ base: "120px", lg: "80px" }}
                    flexFlow={"column wrap"}
                    mt={{ base: "10px", lg: "0" }}
                  >
                    <Box h="40px">
                      <Text className={classSubtitle}>Last Updated</Text>
                    </Box>
                    <Box h="40px">
                      <Text align={"center"}>{updatedAt}</Text>
                    </Box>
                  </Box>
                  <Box
                    width={{ base: "120px", lg: "100px" }}
                    h={{ base: "120px", lg: "80px" }}
                    flexFlow={"column wrap"}
                  >
                    <Box h="40px">
                      <Text className={classSubtitle}>State</Text>
                    </Box>
                    <Box h="40px">
                      <Text>{state}</Text>
                    </Box>
                  </Box>
                  <Box
                    width={{ lg: "130px" }}
                    h={{ base: "120px", lg: "80px" }}
                    display={{ base: "none", lg: "block" }}
                    flexFlow={"column wrap"}
                  >
                    <Text className={classSubtitle}>PDF Download</Text>
                    <Box h="40px" m={"20px auto"}>
                      <Link>
                        <Image
                          onClick={() => router.push(`/documents`)}
                          alt={"Hero Image"}
                          src={"/images/icons/pdf.svg"}
                          height={38}
                          width={42}
                        />
                      </Link>
                    </Box>
                  </Box>
                </HStack>
              </Box>
            </Box>
          </Flex>
        </Container>
      </Box>
      <RatingComponent
        isComplete={isComplete === 2}
        userFirstName={session.first_name ?? ""}
        product={product}
      />
    </Suspense>
  )
}

const Hero = ({ product }: { product: ProductReviewProp }) => {
  return (
    <>
      <Suspense fallback={<LoadingSpinner />}>{<HeroContent product={product} />}</Suspense>
    </>
  )
}

export default Hero
