import { Icon, ListItem, Text, useTheme } from "@chakra-ui/react"

const CircleIcon = ({ fillColor = "currentColor", ...props }) => (
  <Icon viewBox="0 0 200 200" {...props}>
    <path fill={fillColor} d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0" />
  </Icon>
)

const BulletPoint = ({ children, fontSize = "16px", pl = "15px", fillColor = "", ...props }) => {
  const theme = useTheme()
  return (
    <ListItem display={"flex"} alignItems={{ base: "baseline", lg: "top" }}>
      <CircleIcon boxSize={5} fillColor={fillColor || theme.colors.brand.bullet} />
      <Text fontSize={fontSize} as={"span"} pl={pl} {...props}>
        {children}
      </Text>
    </ListItem>
  )
}

export default BulletPoint
