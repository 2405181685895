import Image from "next/legacy/image"
import React, { useEffect, useState } from "react"
import { Box, Text, Button, useDisclosure, Container, Flex, useTheme } from "@chakra-ui/react"
import { Modal, ModalCloseButton, ModalContent, ModalOverlay, ModalHeader } from "@chakra-ui/react"
import ConfirmationText from "./ConfirmationText"
import { InfoIcon } from "@chakra-ui/icons"
import { ProductReviewProp, interviewProps } from "app/utils/divorceHomePage"
import { useLocalStorage } from "app/utils/hooks/useLocalStorage"

interface modalProps {
  isOpen: any
  onOpen?: any
  onClose?: any
  product: ProductReviewProp
  interviewMetaData: interviewProps
}

const UPGRADE_INTEVIEW_DIALOG_ID = "upgradeInterviewDialogWarningSeenId"

const UpgradeInterviewDialogBox = (props: modalProps) => {
  const theme = useTheme()
  const { isOpen, onOpen, onClose, product, interviewMetaData } = props
  const [openConfirmation, setConfirmation] = useState<boolean>(false)
  const [lastTimeSeenExpirationDialog, setUpgradeInterviewDialogWarning] = useLocalStorage(
    UPGRADE_INTEVIEW_DIALOG_ID,
    null
  )

  useEffect(() => {
    setConfirmation(false)
  }, [openConfirmation])

  const onDialogClosed = () => {
    setUpgradeInterviewDialogWarning(interviewMetaData.id)
    onClose()
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onDialogClosed}
        size={{ base: "md", sm: "xl", md: "5xl" }}
        scrollBehavior="inside"
      >
        <ModalOverlay backgroundColor="rgba(0, 0, 0, 0.70)" />
        <ModalContent padding={"15px"} overflowY={"scroll"} m="auto">
          <Box>
            <Image
              alt="Logo"
              src={theme.logoImage.url}
              width={theme.logoImage.width}
              height={theme.logoImage.height}
            />
          </Box>
          <ModalHeader fontSize={"18px"} lineHeight={"24px"} py={"10px"} px={"10px"}>
            Upgrade for Free to Get Latest Court Documents Now
          </ModalHeader>
          <ModalCloseButton />
          <Box py={"10px"} px={"10px"} textAlign="left">
            <Text>
              We&apos;re excited to announce the release of a new divorce questionnaire in your
              state! The courts change divorce requirements often, and we&apos;re committed to
              keeping our product updated so you can complete your divorce quickly and accurately.
              You can now upgrade for free to get access to the latest divorce questions and court
              documents!
            </Text>
            <Text mt="10px">
              We want to help you have a smooth filing experience, so upgrading will increase your
              chances of your documents being accepted by the court.
            </Text>
            <Text mt="15px">
              <strong>Please note: </strong> Upgrading your account will erase your current progress
              and require you to start the new questionnaire from the beginning. To save a copy of
              your current responses for your reference, please download the “Your Data Sheet”
              document from the Documents tab.
            </Text>
          </Box>
          <Box py={"10px"} textAlign={"center"}>
            <Button
              variant={"gradient"}
              width={{ base: "100%", md: "150px", lg: "180px" }}
              height={"50px"}
              onClick={() => {
                onDialogClosed()
                setConfirmation(true)
              }}
            >
              Upgrade
            </Button>
          </Box>
        </ModalContent>
      </Modal>
      <ConfirmationText
        isOpen={openConfirmation}
        product={product}
        interviewMetaData={interviewMetaData}
      />
    </>
  )
}

interface internalprops {
  interviewMetaData: interviewProps
  product: ProductReviewProp
}

const shouldShowDialogOnOpening = (id: number) => {
  if (typeof window === "undefined") return false

  const lastSeenDailogId = window.localStorage.getItem(UPGRADE_INTEVIEW_DIALOG_ID)

  if (lastSeenDailogId === null) return true

  if (Number(lastSeenDailogId) == id) return false

  return true
}

const UpgradeInterviewBanner = (props: internalprops) => {
  const { interviewMetaData, product } = props
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isInterviewComplete, setInterviewComplete] = useState<boolean>(false)
  const shouldShowDialog = shouldShowDialogOnOpening(interviewMetaData.id)

  useEffect(() => {
    if (interviewMetaData && interviewMetaData.metadata && interviewMetaData.metadata != "") {
      const internalMetadata = JSON.parse(interviewMetaData.metadata)
      if (
        internalMetadata.interview_complete == "" &&
        internalMetadata.productVersion == "divorce-legacy" &&
        internalMetadata.state &&
        process.env.NEXT_PUBLIC_PRODUCT_UPGRADE_STATES?.includes(internalMetadata.state)
      ) {
        setInterviewComplete(true)
        if (shouldShowDialog) {
          onOpen()
        }
      }
    }
  }, [interviewMetaData, shouldShowDialog, onOpen])

  return (
    <>
      {isInterviewComplete && (
        <div>
          <Container size="xl" mb={"15px"} mt={"15px"}>
            <Flex
              background={"rgba(63, 159, 227, 0.05)"}
              border="1px solid #0072BB"
              borderLeft="4px solid #0072BB"
              borderRadius="0px 6px 6px 0px"
              padding="20px"
              alignItems={"center"}
              justify={"space-between"}
              gap={"10px"}
              direction={{ base: "column", md: "row" }}
            >
              <Box display="flex" gap={2} textAlign={"center"}>
                <InfoIcon boxSize={10} color="#0072BB" />
                <Text fontSize={"18px"} lineHeight={"24px"} fontWeight={"bold"}>
                  Upgrade For Free to Get Latest Court Documents Now
                </Text>
              </Box>
              <Box>
                <Button
                  variant={"gradient"}
                  width={{ base: "100%", md: "150px", lg: "180px" }}
                  height="50px"
                  onClick={onOpen}
                >
                  Upgrade
                </Button>
              </Box>
            </Flex>
            <UpgradeInterviewDialogBox
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
              product={product}
              interviewMetaData={interviewMetaData}
            />
          </Container>
        </div>
      )}
    </>
  )
}

export default UpgradeInterviewBanner
