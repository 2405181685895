import { useSession, getAntiCSRFToken } from "@blitzjs/auth"
import { useRouter } from "next/router"
import React, { useEffect, useState } from "react"
import {
  Box,
  Button,
  Stack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  Text,
  Input,
  FormControl,
  FormLabel,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useDisclosure,
  Spinner,
} from "@chakra-ui/react"
import { ProductReviewProp, interviewProps } from "app/utils/divorceHomePage"
import { fromAbbreviationToFullStateName } from "app/utils/states"
import { ResponseData } from "pages/api/v1/upgrade_interview"

interface Props {
  isOpen: boolean
  product: ProductReviewProp
  interviewMetaData: interviewProps
}

const ConfirmationText = (props: Props) => {
  const { product, interviewMetaData } = props
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [confirmationText, setConfirmationText] = useState<string | "upgrade">("")
  const [showError, setShowError] = useState<boolean>(false)
  const [button, setButton] = useState<boolean>(true)
  const [loader, setLoader] = useState<boolean>(false)
  const metaData = interviewMetaData.metadata != "" ? JSON.parse(interviewMetaData.metadata) : ""
  const router = useRouter()
  const session = useSession()

  useEffect(() => {
    const getData = setTimeout(() => {
      if (confirmationText.toLowerCase() === "upgrade") {
        setButton(false)
      } else {
        setButton(true)
      }
    }, 1000)

    return () => clearTimeout(getData)
  }, [confirmationText])

  useEffect(() => {
    if (props.isOpen) {
      setShowError(false)
      onOpen()
    }
  }, [props.isOpen, onOpen])

  const handleChange = (event: React.FormEvent<HTMLInputElement>) =>
    setConfirmationText((event.target as HTMLInputElement).value)

  const upgradeToNewInterview = async () => {
    const antiCSRFToken = getAntiCSRFToken()
    if (!antiCSRFToken) {
      console.error("There was an error creating antiCSTFToken")

      return
    }

    const response = await fetch("/api/v1/upgrade_interview", {
      method: "POST",
      headers: {
        "anti-csrf": antiCSRFToken,
      },
    })

    return await response.json()
  }

  const upgradeTonew = async () => {
    if (confirmationText.toLowerCase() === "upgrade") {
      setShowError(false)
      const stateFullName = fromAbbreviationToFullStateName(metaData.state)
      if (stateFullName) {
        setLoader(true)
        setButton(true)

        const newInterviewDetails: ResponseData = await upgradeToNewInterview()
        if (newInterviewDetails && newInterviewDetails.id) {
          router.push(`/documents/divorce/` + newInterviewDetails.id)
        }
      }
    } else {
      setShowError(true)
    }
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay backgroundColor="rgba(0, 0, 0, 0.70)" />
        <ModalContent borderRadius="8px" p="24px" maxW="482px">
          <ModalHeader textAlign="center">Confirmation required?</ModalHeader>
          <ModalCloseButton />
          <Alert status="warning" mt="15px" padding={"10px"}>
            <AlertIcon boxSize="24px" />
            <Box display="flex" flexDirection="column" width={"100%"}>
              <AlertTitle fontSize={"18px"}>Are you sure?</AlertTitle>
              <AlertDescription width={"100%"} fontSize={"15px"} mt="5px" lineHeight={"18px"}>
                Once confirmed, You will upgraded to new questionnaire
              </AlertDescription>
            </Box>
          </Alert>
          <FormControl mt="15px">
            <FormLabel mb="5px">Type UPGRADE to confirm</FormLabel>
            <Input
              p={"10px"}
              height={"40px"}
              value={confirmationText}
              onChange={handleChange}
              fontSize={"18px"}
              size="lg"
            />
            {showError && <Text color={"red.500"}>Please type correct word.</Text>}
          </FormControl>
          <Stack mt={"20px"} spacing={4} direction="row" align="center">
            <Button
              isDisabled={button}
              variant={"gradient"}
              size="sm"
              onClick={upgradeTonew}
              _hover={{
                bg: button ? "#C9C9C9" : "linear-gradient(88.59deg, #0072BB 0%, #1E91D6 0%)",
              }}
            >
              Upgrade Now
            </Button>
            <Button variant={"gradient"} onClick={onClose} size="sm">
              Cancel
            </Button>
            {loader && <Spinner size="xl" />}
          </Stack>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ConfirmationText
