import React from "react"
import { Heading, Stack, List, Container, Box, useTheme } from "@chakra-ui/react"
import HomeContentBox from "./HomeContentBox"
import useBreakpoint from "app/components/UseBreakpoint"
import BulletPoint from "app/components/BulletPoint"

const BulletPointWrap = ({ children }) => {
  const theme = useTheme()
  return (
    <BulletPoint
      fontSize={"16px"}
      pl={"15px"}
      pb={{ base: "10px", lg: "15px" }}
      width={{ base: "auto", lg: "721px" }}
      color="gray.800"
      lineHeight="28px"
      fontWeight="700"
      fillColor={theme.colors.brand.bullet}
    >
      {children}
    </BulletPoint>
  )
}

const Description = () => {
  const breakpoint = useBreakpoint()
  return (
    <Box bgColor="white" p={{ base: "20px 0", md: "10px 0 20px" }}>
      <Container size="xl">
        <Stack>
          <Heading
            color={"gray.900"}
            fontSize={{ base: "28px" }}
            lineHeight={{ base: "36px" }}
            fontWeight="800"
            textAlign={{ base: "center", md: "left" }}
            pb={{ base: "10px", lg: "20px" }}
          >
            How It Works
          </Heading>
          <HomeContentBox size={breakpoint}>
            <List>
              <BulletPointWrap>
                You and your spouse will answer questions regarding aspects of your divorce.
                Depending on the details of the case, some customers are able to answer all the
                questions in an hour or two.
              </BulletPointWrap>
              <BulletPointWrap>
                You can complete the questions at your own pace. All of your answers are saved, and
                you can pause and come back at any time. Although we provide information about the
                questions, you should make sure to pause and do your own research (or research with
                an attorney) about each question.
              </BulletPointWrap>
              <BulletPointWrap>
                You can change your answer to a question at any time before filing with no
                additional fee. After you answer all of the questions completely, the program will
                generate the forms that may be required for your case. The program will also provide
                filing instructions on how to file your divorce case.
              </BulletPointWrap>
              <BulletPointWrap>
                You can alter and re-print your forms as many times as necessary with no additional
                fee.
              </BulletPointWrap>
              <BulletPointWrap>
                You also have the comfort of unlimited free product support. Although we cannot
                provide legal advice or advice specific to your situation, we are here to help you
                with the program from start to finish. If legal advice is needed or if you do not
                feel 100% comfortable in understanding your documents or the questions, you and/or
                your spouse can obtain the services of a lawyer in your area.
              </BulletPointWrap>
              <BulletPointWrap>
                You do not have to use an attorney for your full case; instead they can provide you
                with &ldquo;limited representation&rdquo; and answer the questions you have about
                your case.
              </BulletPointWrap>
            </List>
          </HomeContentBox>
        </Stack>
      </Container>
    </Box>
  )
}

export default Description
